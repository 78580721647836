import React from 'react';
import {
  Route,
  Redirect
} from "react-router-dom";
import { realm_app } from 'storage/realm';


export default function PrivateRoute({ children, ...rest }) {
  if (realm_app?.currentUser?.isLoggedIn === true) {
    return (
      <Route {...rest} >
        {children}
      </Route>
    )
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { referrer: rest.location.pathname }
        }}
      />
    )
  }
}
