import { Button, withStyles } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import BarcodeScannerIcon from 'storage/icons/BarcodeScannerIcon';

const styles = () => ({
  nav: {
    width: '100vw',
    padding: '50px 1vw'
  },
  ul: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    listStyle: 'none',
  },
  button: {
    backgroundColor: 'red',
    color: 'white',
  },
  about: {
    padding: '20px',
    textAlign: 'justify',
  }
});

class Home extends React.Component {
  constructor(props){
    super(props);
    this.goTo = this.goTo.bind(this);
  }

  goTo(path) {
    return () => {
      this.props.history.push(path);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <nav className={classes.nav}>
          <ul className={classes.ul}>
            <li >
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<BarcodeScannerIcon />}
                onClick={this.goTo('/comparar-mercados')}
              >
                Pesquisar
              </Button>
            </li>
            {/* <li>
              <Link to="/comparar-produto" className={classes.button}>
                Vale a pena?
              </Link>
            </li> */}
          </ul>
        </nav>
        <article className={classes.about}>
          <section>
            <h2>O que é?</h2>
            <p>
              <strong>Ta Barato</strong> é um aplicativo colaborativo de comparação de preços. Com ele você
              pode comparar o preço de um produto entre varios estabelecimentos em um raio
              de 5km e economizar o seu rico dinheirinho.</p>
          </section>
          <section>
            <h2>Como usar?</h2>
            <p>
              Utilizar o <strong>Ta Barato</strong> é muito fácil!! <br/> Basta ler o código de barras de qualquer
              produto utilizando a camêra do seu celular e em seguinda informar o valor.
              Pronto! agora é por nossa conta, vamos comparar o preço informado com o que outras pessoas
              estão postando no aplicativo entre os estabelecimentos em um raio de 5km.</p>
          </section>
          <section>
            <h2>Quanto custa?</h2>
            <p>
              Nadica de nada!! <br/>
              Não cobramos nada alem da sua colaboração informando o preço dos items que você esta pesquisando.
            </p>
          </section>
          <section>
            <h2>Quem somos?</h2>
            <p>
              Somos um casal de consumidores, que assim como você, não se conforma em pagar a mais pelo mesmo produto.
            </p>
          </section>
        </article>
      </div>
    );
  }
}

const enhance = compose(
  withStyles(styles),
  withRouter,
)

export default enhance(Home);
