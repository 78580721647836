import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Quagga from 'quagga';
import React from 'react';
import { compose } from "recompose";


const camConfigDefault = {
  frequency: 10,
  locate: false,
  inputStream:
  {
    name: 'Live',
    type: 'LiveStream',
  },
  decoder: {
    readers: [
      'ean_reader',
      {
        format: 'ean_reader',
        config: {
          supplements: [
            'ean_5_reader', 'ean_2_reader',
          ],
        },
      },
      'code_128_reader',
    ],
  },
};

const styles = () => ({
  videoFrame: {
    '& canvas': {
      display: 'none',
    },
    '& br': {
      display: 'none',
    },
    '& video': {
      width: '80vw',
    },
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '20px',
  },
});

class Scanner extends React.Component {
  constructor(props) {
    super(props);
    this.videoFrameRef = React.createRef();
  }

  componentDidMount() {
    const camConfig = {
      ...camConfigDefault,
      ...{
        numOfWorkers: navigator.hardwareConcurrency,
        inputStream:
        {
          ...camConfigDefault.inputStream,
          target: this.videoFrameRef.current,
        },
      },
    };

    Quagga.init(camConfig, (err) => {
      if (err) {
        return;
      }
      Quagga.start();
    });

    Quagga.onDetected(this.props.onCodeScanned);
  }

  componentWillUnmount() {
    Quagga.offDetected(this.props.onCodeScanned);
    Quagga.stop();
  }

  render() {
    const { classes } = this.props;

    return (
      <div ref={this.videoFrameRef} className={classes.videoFrame}>
      </div>
    );
  }
}

Scanner.propTypes = {
  classes: PropTypes.object.isRequired,
  onCodeScanned: PropTypes.func.isRequired,
};


const enhance = compose(
  withStyles(styles),
)

export default enhance(Scanner);
