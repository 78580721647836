import { withStyles } from '@material-ui/core';
import { HomeRounded } from '@material-ui/icons';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

const styles = () => ({
  header: {
    '& svg': {
      '& .text': {
        fill: 'white',
      },
      "& .outline": {
        fill: 'red',
      }
    }
  },
  homeIcon: {
    position: 'Absolute',
    top: '10px',
    right: '10px',
    color: 'white',
  }
});

class Header extends React.Component {
  constructor(props){
    super(props);
    this.goTo = this.goTo.bind(this);
  }

  goTo(path) {
    return () => {
      this.props.history.push(path);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <header className={classes.header}>
        <svg xmlns="http://www.w3.org/2000/svg"
          width="100%" height="100%"
          viewBox="0 0 750 321">
          <defs>
            <filter id="f1" x="0" y="0" width="200%" height="200%">
              <feOffset result="offOut" in="SourceGraphic" dx="20" dy="20" />
              <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>
          </defs>
          <path className="text" filter="url(#f1)"

            d="M 186.00,9.74
           C 186.00,9.74 166.00,27.72 166.00,27.72
             166.00,27.72 153.62,38.30 153.62,38.30
             149.88,43.69 157.60,47.78 165.00,44.78
             168.59,43.32 173.80,39.27 177.00,36.87
             181.45,33.54 193.65,24.02 196.68,19.99
             202.04,12.86 194.54,7.75 186.00,9.74 Z
           M 398.00,87.00
           C 408.56,86.76 411.75,83.78 414.89,74.00
             415.83,71.06 418.60,63.03 422.87,64.34
             426.97,65.59 425.85,72.03 424.91,75.00
             422.61,82.22 417.59,91.08 419.79,99.00
             420.66,102.12 422.45,106.01 426.04,106.50
             427.48,106.70 429.64,106.12 430.98,105.60
             439.43,102.27 431.95,98.29 434.78,88.00
             436.48,81.83 439.66,77.85 439.96,71.00
             440.74,53.11 422.15,48.00 410.00,55.22
             398.83,61.86 395.86,75.04 398.00,87.00 Z
           M 194.00,126.00
           C 198.13,136.75 198.16,147.93 211.00,145.33
             213.36,144.86 217.33,144.16 218.36,141.70
             219.25,139.58 217.88,134.30 217.35,132.00
             217.35,132.00 212.21,110.00 212.21,110.00
             212.21,110.00 204.42,71.00 204.42,71.00
             203.64,66.67 203.20,58.36 199.72,55.60
             196.08,52.70 183.71,53.51 179.00,53.93
             174.88,54.29 169.63,54.66 166.10,57.01
             160.58,60.68 158.95,71.79 157.58,78.00
             157.58,78.00 146.59,132.00 146.59,132.00
             145.49,138.53 141.04,152.32 149.05,155.39
             149.05,155.39 154.00,156.56 154.00,156.56
             167.36,158.65 159.84,144.28 167.28,137.84
             172.36,133.44 187.16,129.51 194.00,126.00 Z
           M 60.00,88.00
           C 62.19,93.59 69.43,102.65 76.00,102.69
             79.18,102.70 86.47,99.23 90.00,98.00
             90.00,98.00 91.00,99.00 91.00,99.00
             90.02,107.80 91.86,135.27 92.83,145.00
             93.23,148.98 93.80,159.77 95.43,162.89
             98.09,168.01 112.85,170.71 115.20,164.85
             116.76,161.73 115.01,145.82 115.20,141.00
             115.20,141.00 116.00,96.00 116.00,96.00
             116.06,87.20 117.30,87.49 129.00,83.67
             131.54,82.84 137.20,81.95 137.48,78.78
             137.85,74.78 131.64,64.30 120.00,66.67
             120.00,66.67 92.00,75.33 92.00,75.33
             81.80,78.76 69.26,82.75 60.00,88.00 Z
           M 182.00,76.00
           C 182.00,76.00 187.80,100.00 187.80,100.00
             188.25,102.57 189.65,107.10 187.80,109.44
             186.10,112.05 174.36,114.70 171.00,115.00
             171.00,115.00 177.29,89.00 177.29,89.00
             177.29,89.00 182.00,76.00 182.00,76.00 Z
           M 378.00,82.47
           C 372.19,83.71 366.59,84.99 362.10,89.21
             350.84,99.81 355.05,122.73 361.27,135.00
             366.07,144.45 376.25,156.15 388.00,154.55
             391.70,154.04 394.70,152.93 398.00,151.21
             418.69,140.46 412.85,119.10 404.24,102.00
             398.85,91.29 391.31,80.92 378.00,82.47 Z
           M 376.43,96.63
           C 381.24,96.29 383.12,99.30 385.64,103.00
             390.25,109.79 394.66,120.90 396.08,129.00
             396.73,132.71 397.98,139.57 393.72,141.72
             388.83,144.19 384.27,137.39 382.20,134.00
             377.28,125.92 374.11,116.51 374.00,107.00
             373.96,103.08 373.22,99.27 376.43,96.63 Z
           M 320.00,136.00
           C 321.09,143.03 324.06,149.40 326.58,156.00
             329.09,162.60 335.23,177.63 339.10,182.94
             344.77,190.72 355.60,184.60 356.77,181.79
             357.97,178.88 352.66,168.20 351.28,165.00
             351.28,165.00 341.33,138.00 341.33,138.00
             341.33,138.00 337.33,124.17 337.33,124.17
             337.11,118.91 347.16,115.68 348.83,111.68
             350.56,107.55 342.34,101.67 336.00,104.09
             331.56,105.78 310.01,121.37 305.00,125.13
             302.60,126.92 296.09,131.27 295.34,134.00
             294.52,136.94 297.90,138.89 300.00,140.12
             309.90,145.88 311.64,140.41 320.00,136.00 Z
           M 437.00,107.64
           C 433.44,108.63 429.91,110.18 428.62,114.01
             427.08,118.57 430.93,122.48 436.00,121.39
             444.49,119.57 447.74,107.79 437.00,107.64 Z
           M 281.00,137.44
           C 275.49,138.47 265.12,140.93 261.43,145.21
             258.61,148.49 258.30,157.20 258.10,161.58
             258.10,161.58 258.10,180.00 258.10,180.00
             258.10,180.00 259.04,197.00 259.04,197.00
             259.04,197.00 259.04,209.00 259.04,209.00
             259.04,209.00 260.01,219.00 260.01,219.00
             260.10,222.65 260.20,228.72 262.72,231.57
             265.60,234.82 275.37,234.98 277.40,230.70
             278.30,228.82 277.96,222.40 278.00,220.00
             278.16,211.40 284.55,208.31 291.00,203.47
             292.72,202.19 296.93,198.91 298.96,198.73
             305.02,198.20 305.45,211.06 315.00,209.64
             319.18,209.02 325.42,206.05 324.30,200.98
             324.30,200.98 312.28,180.00 312.28,180.00
             312.28,180.00 298.25,154.00 298.25,154.00
             298.25,154.00 291.47,140.13 291.47,140.13
             288.75,136.15 285.23,136.90 281.00,137.44 Z
           M 278.00,158.00
           C 278.00,158.00 292.00,184.00 292.00,184.00
             292.00,184.00 277.00,193.00 277.00,193.00
             277.00,193.00 277.00,171.00 277.00,171.00
             277.00,171.00 278.00,158.00 278.00,158.00 Z
           M 209.00,235.00
           C 219.00,237.83 233.59,246.04 244.00,245.92
             251.31,245.83 260.12,238.93 256.36,234.84
             255.01,233.38 249.11,231.72 247.00,231.00
             247.00,231.00 226.00,223.00 226.00,223.00
             231.92,214.48 241.06,205.53 243.47,195.00
             246.29,182.71 240.33,175.10 230.00,169.16
             226.85,167.34 223.71,165.53 220.00,165.22
             209.48,164.36 198.43,172.48 191.00,179.05
             188.67,181.11 184.56,184.40 183.17,187.04
             181.84,189.59 181.95,193.17 182.01,196.00
             182.23,205.40 187.72,232.05 190.13,242.00
             191.09,245.97 193.20,257.07 195.09,259.78
             198.54,264.96 210.81,263.40 212.18,259.78
             213.10,257.94 212.27,253.97 212.18,252.00
             212.18,252.00 209.00,235.00 209.00,235.00 Z
           M 207.00,215.00
           C 207.00,215.00 205.18,195.00 205.18,195.00
             206.00,187.66 219.45,180.71 223.35,184.60
             225.96,187.13 224.33,192.11 223.35,195.00
             220.54,202.77 214.55,211.43 207.00,215.00 Z
           M 163.00,255.00
           C 163.67,258.73 166.91,268.84 169.65,271.26
             173.01,274.24 185.67,273.29 187.31,268.73
             188.19,266.28 185.84,260.53 185.00,258.00
             185.00,258.00 177.14,232.00 177.14,232.00
             177.14,232.00 169.58,204.00 169.58,204.00
             168.86,200.78 167.28,191.82 165.19,189.74
             163.36,187.91 160.40,188.04 158.00,188.00
             151.05,187.91 137.36,187.31 132.65,193.14
             129.54,196.99 127.01,209.76 125.77,215.00
             125.77,215.00 115.58,257.00 115.58,257.00
             114.74,260.73 111.07,276.13 111.60,278.90
             112.45,283.34 121.98,286.37 125.89,285.60
             132.26,284.33 129.81,272.62 134.51,267.39
             137.60,263.95 157.80,256.40 163.00,255.00 Z
           M 93.00,246.00
           C 106.41,239.66 114.20,224.10 105.16,211.00
             102.64,207.34 99.09,204.02 95.00,202.21
             88.65,199.40 84.51,200.12 78.00,201.25
             69.93,202.65 62.60,204.52 55.00,207.60
             52.08,208.79 47.68,210.40 46.02,213.21
             44.61,215.61 45.01,221.12 45.00,224.00
             45.00,224.00 40.02,275.00 40.02,275.00
             40.10,277.24 40.22,278.93 41.31,280.96
             43.98,285.92 54.43,292.42 60.00,293.32
             64.47,294.04 69.84,292.14 74.00,290.58
             84.50,286.63 103.18,277.48 104.77,265.00
             105.85,256.53 98.62,250.88 93.00,246.00 Z
           M 149.00,208.00
           C 149.00,208.00 157.00,239.00 157.00,239.00
             157.00,239.00 139.00,244.00 139.00,244.00
             139.00,244.00 144.63,221.00 144.63,221.00
             144.63,221.00 149.00,208.00 149.00,208.00 Z
           M 66.00,242.00
           C 66.10,237.41 67.06,225.79 70.43,222.70
             72.09,221.17 75.82,219.85 78.00,219.40
             80.70,218.41 86.15,217.11 88.31,219.40
             90.65,222.30 87.41,226.85 85.66,229.00
             80.91,234.82 73.22,239.94 66.00,242.00 Z
           M 60.00,276.00
           C 60.15,272.72 61.18,262.87 62.98,260.43
             65.84,256.54 79.68,255.47 81.87,259.43
             83.39,262.17 80.66,265.43 78.79,267.18
             73.82,271.82 66.66,274.96 60.00,276.00 Z" />
          <path className="outline"
            d="M 0.00,0.00
           C 0.00,0.00 0.00,305.00 0.00,305.00
             0.00,305.00 16.17,309.85 16.17,309.85
             16.17,309.85 36.91,314.25 36.91,314.25
             36.91,314.25 42.17,315.76 42.17,315.76
             42.17,315.76 54.91,317.25 54.91,317.25
             54.91,317.25 60.17,318.93 60.17,318.93
             60.17,318.93 67.00,318.93 67.00,318.93
             67.00,318.93 79.00,320.00 79.00,320.00
             79.00,320.00 120.00,320.00 120.00,320.00
             132.25,319.98 148.09,316.95 160.09,314.22
             160.09,314.22 167.83,312.85 167.83,312.85
             206.06,303.19 241.47,285.75 275.00,265.23
             362.61,211.59 432.85,139.86 531.16,102.30
             531.16,102.30 567.09,90.18 567.09,90.18
             567.09,90.18 587.83,85.80 587.83,85.80
             587.83,85.80 593.09,84.26 593.09,84.26
             593.09,84.26 605.91,82.89 605.91,82.89
             605.91,82.89 612.09,81.26 612.09,81.26
             612.09,81.26 625.00,79.88 625.00,79.88
             625.00,79.88 635.83,79.88 635.83,79.88
             640.54,78.94 637.64,78.02 647.00,78.00
             647.00,78.00 669.00,78.00 669.00,78.00
             680.09,78.02 674.89,79.98 686.00,80.00
             686.00,80.00 709.00,81.26 709.00,81.26
             709.00,81.26 715.00,82.75 715.00,82.75
             715.00,82.75 727.00,84.24 727.00,84.24
             734.12,85.58 742.97,88.93 750.00,89.00
             750.00,89.00 750.00,0.00 750.00,0.00
             750.00,0.00 0.00,0.00 0.00,0.00 Z
           M 187.00,7.43
           C 197.05,6.15 203.10,14.17 199.41,19.42
             196.77,23.20 188.77,29.37 185.00,32.46
             179.70,36.81 170.65,44.84 164.00,46.53
             158.41,47.94 146.94,45.26 152.60,37.42
             152.60,37.42 174.83,17.58 174.83,17.58
             179.34,13.25 180.85,9.84 187.00,7.43 Z
           M 425.00,64.00
           C 411.89,65.93 420.13,79.27 407.00,86.23
             405.07,87.26 402.31,88.14 400.11,87.65
             393.23,86.10 396.95,72.46 398.55,68.00
             403.90,53.09 421.62,45.54 434.91,55.64
             447.88,65.41 436.56,81.73 434.91,90.96
             434.02,98.20 438.36,100.66 434.91,104.57
             429.81,109.67 422.77,108.00 419.90,102.00
             417.89,97.80 418.30,93.38 419.21,89.00
             421.59,77.64 425.88,76.67 425.00,64.00 Z
           M 194.00,128.00
           C 194.00,128.00 176.00,135.01 176.00,135.01
             173.46,136.00 169.50,137.39 167.73,139.47
             163.02,145.05 167.48,158.08 157.00,158.41
             153.35,158.52 146.19,157.09 144.17,153.78
             142.77,151.46 142.90,146.69 143.09,144.00
             143.09,144.00 147.07,119.17 147.07,119.17
             147.07,119.17 150.72,103.00 150.72,103.00
             150.72,103.00 156.42,75.00 156.42,75.00
             157.71,69.16 159.49,60.40 164.19,56.43
             169.91,51.60 184.69,51.92 192.00,52.00
             196.09,52.05 200.35,52.06 202.57,56.13
             205.21,60.96 205.74,72.33 206.80,78.00
             206.80,78.00 210.57,93.00 210.57,93.00
             210.57,93.00 216.40,121.00 216.40,121.00
             217.16,124.80 220.96,139.12 220.09,141.87
             218.48,146.98 204.21,148.43 200.50,145.26
             197.25,142.50 194.39,132.25 194.00,128.00 Z
           M 89.00,100.00
           C 77.73,103.91 73.02,107.24 63.62,96.83
             61.54,94.52 58.28,90.62 60.07,87.34
             61.58,84.58 74.45,79.92 78.00,78.51
             78.00,78.51 108.00,68.51 108.00,68.51
             111.82,67.23 122.31,64.09 126.00,64.39
             131.24,64.82 138.56,72.82 139.14,77.98
             139.95,85.25 124.70,85.28 120.29,88.89
             116.62,91.88 117.01,104.28 117.00,109.00
             117.00,109.00 117.00,155.00 117.00,155.00
             117.04,158.35 118.30,164.73 116.09,167.48
             113.15,170.67 101.57,169.58 98.11,167.48
             96.40,166.75 95.50,165.70 94.70,163.95
             93.00,160.24 91.35,146.58 90.93,142.00
             90.93,142.00 89.00,100.00 89.00,100.00 Z
           M 181.00,80.00
           C 181.00,80.00 173.00,114.00 173.00,114.00
             173.00,114.00 188.00,109.00 188.00,109.00
             187.70,102.57 183.82,85.52 181.00,80.00 Z
           M 378.83,81.42
           C 391.21,80.67 397.12,88.28 403.10,98.00
             406.34,103.26 408.53,109.10 410.28,115.00
             414.97,130.78 414.78,144.47 398.00,152.44
             394.98,153.88 391.81,155.23 388.47,155.59
             376.49,156.90 368.48,148.10 362.51,139.00
             354.03,126.05 348.70,98.66 362.51,87.39
             366.92,83.79 373.31,82.17 378.83,81.42 Z
           M 376.58,98.13
           C 374.44,100.96 374.96,103.53 375.01,107.00
             375.15,116.97 379.73,131.93 387.18,138.77
             393.15,144.24 396.20,139.67 395.76,133.28
             395.13,124.24 390.21,112.85 385.76,105.04
             383.72,101.45 381.20,97.14 376.58,98.13 Z
           M 319.00,137.00
           C 311.55,143.04 308.29,146.48 299.00,140.91
             296.70,139.52 292.97,137.25 293.74,134.05
             294.47,131.06 301.43,126.29 304.00,124.42
             309.92,120.13 330.67,105.09 336.00,103.09
             340.25,101.48 349.06,104.46 350.26,109.14
             351.80,115.16 338.24,117.79 338.66,125.00
             338.80,127.37 342.55,138.25 343.58,141.00
             343.58,141.00 352.73,166.00 352.73,166.00
             354.09,169.37 358.86,178.72 358.10,181.89
             357.11,186.04 344.65,191.26 338.90,184.66
             336.25,181.61 333.91,175.76 332.08,172.00
             327.79,163.18 321.13,146.42 319.00,137.00 Z
           M 437.71,106.57
           C 449.51,107.19 445.38,121.54 435.00,122.52
             429.20,123.06 425.38,117.22 428.18,112.10
             430.11,108.58 434.03,107.26 437.71,106.57 Z
           M 300.00,198.00
           C 295.21,202.21 282.46,210.25 280.02,215.01
             276.80,221.31 282.32,229.44 277.70,233.12
             275.75,234.67 261.18,239.20 259.36,226.42
             258.40,219.70 258.01,212.79 258.00,206.00
             258.00,206.00 257.06,181.00 257.06,181.00
             257.06,181.00 257.06,162.84 257.06,162.84
             257.03,158.76 257.39,149.48 259.30,146.11
             262.26,140.88 276.16,137.03 282.00,136.09
             284.71,135.65 287.42,135.15 289.86,136.78
             292.67,138.66 296.74,148.54 298.42,152.00
             298.42,152.00 318.00,187.00 318.00,187.00
             319.84,190.06 326.54,199.88 326.30,202.89
             326.08,205.54 323.10,207.12 321.07,208.37
             310.25,215.05 305.12,206.82 300.00,198.00 Z
           M 278.00,159.00
           C 278.00,159.00 278.00,191.00 278.00,191.00
             278.00,191.00 291.00,183.00 291.00,183.00
             291.00,183.00 278.00,159.00 278.00,159.00 Z
           M 228.00,222.00
           C 228.00,222.00 250.00,230.91 250.00,230.91
             252.57,231.83 257.44,232.57 258.61,235.27
             260.66,239.99 252.61,245.10 249.00,246.30
             246.88,247.01 244.23,247.05 242.00,246.96
             233.07,246.60 218.84,240.36 211.00,236.00
             211.00,236.00 213.19,251.28 213.19,251.28
             213.48,253.94 214.57,258.17 213.19,260.76
             211.66,264.53 198.44,267.22 194.08,260.76
             191.79,257.40 190.28,248.25 189.26,244.00
             186.51,232.56 181.13,207.09 181.00,196.00
             180.98,193.58 180.82,189.33 181.74,187.17
             182.91,184.38 187.50,180.56 189.83,178.51
             196.76,172.42 206.41,164.76 216.00,164.10
             227.70,163.28 243.25,174.32 245.36,186.00
             246.21,190.73 244.64,195.61 242.93,200.00
             239.93,207.75 233.24,215.60 228.00,222.00 Z
           M 208.00,213.00
           C 214.86,209.83 222.95,197.64 223.35,190.00
             223.49,187.41 222.91,186.30 222.00,184.00
             217.05,185.33 209.76,187.31 207.05,192.17
             205.42,195.09 207.39,209.11 208.00,213.00 Z
           M 162.00,256.00
           C 156.60,258.55 138.50,265.05 135.51,268.39
             130.87,273.56 133.51,285.63 125.96,286.74
             120.07,287.60 111.00,284.74 110.39,277.98
             110.03,274.01 113.42,260.50 114.53,256.00
             114.53,256.00 125.00,213.00 125.00,213.00
             126.37,207.74 128.69,195.03 132.42,191.51
             136.30,187.83 151.62,185.78 157.00,186.09
             165.90,186.61 166.67,188.29 168.75,196.00
             168.75,196.00 170.99,204.00 170.99,204.00
             170.99,204.00 183.33,248.00 183.33,248.00
             184.58,251.75 189.75,265.68 188.94,268.78
             187.87,272.84 174.42,276.92 169.34,273.15
             165.65,270.40 163.31,260.49 162.00,256.00 Z
           M 95.00,247.00
           C 105.62,252.72 109.90,263.91 101.96,273.91
             94.74,283.00 73.91,295.30 62.00,294.73
             59.35,294.60 55.42,293.21 53.00,292.10
             45.55,288.67 39.16,283.68 39.00,275.00
             39.00,275.00 43.01,235.99 43.01,235.99
             43.01,235.99 44.60,213.19 44.60,213.19
             45.34,211.43 46.47,210.60 48.06,209.65
             55.92,204.91 70.11,201.32 79.28,200.27
             84.74,199.44 88.40,198.44 93.91,200.27
             96.77,201.42 99.61,203.64 101.83,205.67
             117.57,220.03 109.57,235.97 95.00,247.00 Z
           M 148.00,210.00
           C 148.00,210.00 140.00,243.00 140.00,243.00
             140.00,243.00 156.00,238.00 156.00,238.00
             156.00,238.00 148.00,210.00 148.00,210.00 Z
           M 67.00,240.00
           C 77.04,237.61 85.56,229.60 89.00,220.00
             83.31,218.62 73.61,219.61 70.17,225.22
             68.91,227.28 67.55,236.99 67.00,240.00 Z
           M 61.00,275.00
           C 67.33,273.11 71.70,271.48 76.90,267.10
             78.62,265.65 81.53,262.88 80.59,260.31
             79.09,256.23 67.31,258.25 64.74,260.81
             62.82,262.72 61.57,271.96 61.00,275.00 Z" />
        </svg>
        <HomeRounded className={classes.homeIcon} onClick={this.goTo('/')}/>
      </header>
    );
  }
}

const enhance = compose(
  withStyles(styles),
  withRouter
)

export default enhance(Header);
