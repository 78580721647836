
import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';

import { realm_app } from 'storage/realm';
import * as Realm from 'realm-web';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const styles = () => ({
  container: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.facebookLogin = this.facebookLogin.bind(this);
    if(window.location.hash){
      Realm.handleAuthRedirect();
    }
  }

  facebookLogin() {
    const credentials = Realm.Credentials.facebook(`${process.env.REACT_APP_DOMAIN}/login`)
    realm_app.logIn(credentials).then(resp => {
      this.props.history.push(this.props.location.state?.referrer || '/');
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <section className={classes.container}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<FacebookIcon />}
          onClick={this.facebookLogin}
        >
          Entrar com Facebook
        </Button>
      </section>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter
);

export default enhance(Login);
