import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from "recompose";
import Scanner from './Scanner';
import PriceReader from './PriceReader';
import Result from './Result';
import ReactGA from 'react-ga';
import { realm_app } from 'storage/realm';
import { geolocated } from 'react-geolocated';
import LocationBlocked from 'components/LocationBlocked';

const styles = () => ({

});

class BarcodeReader extends React.Component {
  constructor() {
    super();
    this.state = {
      isScanningCode: true,
      isReadingPrice: false,
      isShowingResult: false,
      offer: null,
    }
    this.onCodeScanned = this.onCodeScanned.bind(this);
    this.onPriceReaded = this.onPriceReaded.bind(this);
    this.onNewConsult = this.onNewConsult.bind(this);
    this.cancelPriceRead = this.cancelPriceRead.bind(this);
    this.pricesCollection = realm_app
      .currentUser
      .mongoClient(process.env.REACT_APP_REALM_SERVICE_NAME)
      .db(process.env.REACT_APP_ATLAS_DB_NAME)
      .collection('prices');
    ReactGA.event({category: process.env.REACT_APP_GA_STORE_COMPARISON, 'action': 'scanning'});
  }

  componentDidUpdate(prevProps) {
    const { coords } = this.props;
    if (prevProps?.coords?.latitude !== coords?.latitude ||
        prevProps?.coords?.longitude !== coords?.longitude) {
        this.setState({
          location: [coords.latitude, coords.longitude],
        });
    }
  }

  onCodeScanned(barcode) {
    let offer = null;
    ReactGA.event({category: process.env.REACT_APP_GA_STORE_COMPARISON, 'action': 'scanned'});
    const query = {
      '$and': [
        { 'barcode': { '$eq': barcode.codeResult.code }},
        { 'location': {
            '$near': {
              '$geometry': {
                'type': 'Point',
                'coordinates': this.state.location
              },
              '$minDistance': parseInt(process.env.REACT_APP_MIN_DISTANCE),
              '$maxDistance': parseInt(process.env.REACT_APP_MAX_DISTANCE)
            }
          }
        },
      ]
    };
    this.pricesCollection.findOne(
      query,
      {sort: {price: 1}}
    ).then(response => {
      offer = response;
      ReactGA.event({category: process.env.REACT_APP_GA_STORE_COMPARISON, 'action': 'offer-found'});
    }).catch(err => {
      ReactGA.event({category: process.env.REACT_APP_GA_STORE_COMPARISON, 'action': 'offer-not-found'});
    });
    this.setState({
      barcode: barcode.codeResult.code,
      isScanningCode: false,
      isReadingPrice: true,
      offer: offer,
    });
  }

  onPriceReaded(price, quantity) {
    this.setState({
      price,
      quantity,
      isScanningCode: false,
      isReadingPrice: false,
      isShowingResult: true
    });

    if (price === 0.0){
      alert('informe o valor do produto!');
      return;
    }
    ReactGA.event({category: process.env.REACT_APP_GA_STORE_COMPARISON, 'action': 'price-readed'});
    const unitaryPrice = parseFloat((price / quantity).toFixed(2));
    const payload = {
      barcode: this.state.barcode,
      price: unitaryPrice,
      user_id: realm_app.currentUser.id,
      location: {
        type: 'Point',
        coordinates: this.state.location
      },
      createdAt: new Date().getTime(),
      version: 1
    };
    this.pricesCollection.insertOne(payload).then(response => {
      ReactGA.event({category: process.env.REACT_APP_GA_STORE_COMPARISON, 'action': 'price-inserted'});
      console.log(response);
    }).catch(err => {
      ReactGA.event({category: process.env.REACT_APP_GA_STORE_COMPARISON, 'action': 'price-not-inserted'});
      console.log(err);
    });
  }

  onNewConsult() {
    this.setState({
      isScanningCode: true,
      isReadingPrice: false,
      isShowingResult: false
    })
    ReactGA.event({category: process.env.REACT_APP_GA_STORE_COMPARISON, 'action': 'scanning'});
  }

  cancelPriceRead() {
    this.setState({
      isScanningCode: true,
      isReadingPrice: false,
      isShowingResult: false
    })
    ReactGA.event({category: process.env.REACT_APP_GA_STORE_COMPARISON, 'action': 'cancel'});
  }

  render() {
    const { classes, isGeolocationEnabled } = this.props;
    const { isScanningCode, isReadingPrice, isShowingResult, barcode, offer, price} = this.state;

    return (
      <main className={classes.poster}>
          {isGeolocationEnabled === false ? <LocationBlocked/> : ''}
          { isScanningCode && isGeolocationEnabled ?
            <Scanner onCodeScanned={this.onCodeScanned} />
            :
            ''
          }
          { isReadingPrice && isGeolocationEnabled ?
            <PriceReader
              onPriceReaded={this.onPriceReaded}
              barcode={barcode}
              onCancelPriceRead={this.cancelPriceRead}/>
            :
            ''
          }
          { isShowingResult && isGeolocationEnabled ?
            <Result
              price={price}
              offer={offer?.price}
              barcode={barcode}
              onNewConsult={this.onNewConsult}/>
            :
            ''
          }
      </main>
    );
  }
}

BarcodeReader.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.array,
  isGeolocationAvailable: PropTypes.bool.isRequired,
  isGeolocationEnabled: PropTypes.bool.isRequired,
  coords: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  geolocated(),
)

export default enhance(BarcodeReader);
