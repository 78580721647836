
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from "recompose";
import { withStyles } from '@material-ui/styles';
import Keyboard from 'BarcodeReader/Keyboard';
import BarcodeIcon from 'storage/icons/BarcodeIcon';

const styles = () => ({
  container: {
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  priceDisplay:{
    fontSize: '4rem',
  },
  currency: {
    fontSize: '0.5em',
  },
  primaryValue: {
  },
  comma: {
    fontSize: '0.5em',
    position: 'relative',
    top: '-1.2em',
  },
  decimal: {
    fontSize: '0.4em',
    position: 'relative',
    top: '-1em',
    left: '0.1em',
  },
  quantity: {
    fontSize: '0.4em',
    position: 'relative',
    left: '-1.4em',
  },
  unity: {
    fontSize: '0.4em',
    position: 'relative',
    left: '-1em',
  },

  barcodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '1.5rem',
    lineHeight: 0,
  },
  barcodeIcon: {
    '&>svg': {
      fontSize: '4rem',
    },
    '&>svg:not(:first-child)': {
      marginLeft: '-6px'
    }
  }

});

class PriceReader extends React.Component {
  state = {
    price: 0.0,
    quantity: 1,
  }

  constructor() {
    super();
    this.handleCompare = this.handleCompare.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleCompare() {
    this.props.onPriceReaded(this.state.price, this.state.quantity);
  }

  handleChange(price, quantity) {
    this.setState({
      price,
      quantity
    });
  }

  render() {
    const { classes, barcode, onCancelPriceRead } = this.props;
    const { quantity, price } = this.state;

    const integer_price = Math.floor(price);
    const decimal_price = (Math.fround(price % 1).toFixed(2) * 100).toFixed(0);

    return (
      <div className={classes.container}>
        <div className={classes.priceDisplay}>
          <span className={classes.currency}>R$</span>
          <span className={classes.primaryValue}>{String(integer_price).padStart(2, '0')}</span>
          <span className={classes.comma}>,</span>
          <span className={classes.decimal}>{String(decimal_price).padStart(2, '0')}</span>
          <span className={classes.quantity}>{quantity}</span>
          <span className={classes.unity}>Un.</span>
        </div>
        <div className={classes.barcodeContainer}>
          <span className={classes.barcodeIcon}>
            <BarcodeIcon/>
            <BarcodeIcon/>
          </span>
          {barcode}
        </div>
        <Keyboard
          price={price}
          quantity={quantity}
          onChange={this.handleChange}
          onSearch={this.handleCompare}
          onCancelPriceRead={onCancelPriceRead}/>
      </div>
    );
  }
}

PriceReader.propTypes = {
  classes: PropTypes.object.isRequired,
  barcode: PropTypes.string.isRequired,
  onPriceReaded: PropTypes.func.isRequired,
  onCancelPriceRead: PropTypes.func.isRequired,
};


const enhance = compose(
  withStyles(styles),
)
export default enhance(PriceReader);
