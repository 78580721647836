
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from "recompose";
import { withStyles } from '@material-ui/styles';
import ReactGA from 'react-ga';
import { Button } from '@material-ui/core';
import BarcodeScannerIcon from 'storage/icons/BarcodeScannerIcon';

const styles = () => ({
  container: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '100%',
  },
  result: {
      fontSize: '2rem',
  },
  button: {
    backgroundColor: 'red',
    color: 'white',
  }
});

class Result extends React.Component {
  render() {
    let { classes, price, offer, onNewConsult } = this.props;

    price = price || 0.0;
    offer = offer || Infinity;

    let result = 'Ta caro! :(';
    if (price <= offer) {
      result = 'Ta barato!! :D';
      ReactGA.event({category: process.env.REACT_APP_GA_STORE_COMPARISON, 'action': 'under-price'});
    } else {
      ReactGA.event({category: process.env.REACT_APP_GA_STORE_COMPARISON, 'action': 'over-price'});
    }

    return (
      <div className={classes.container}>
        <h1 className={classes.result}>
        {result}
        </h1>
        <Button
          variant="contained"
          className={classes.button}
          startIcon={<BarcodeScannerIcon />}
          onClick={onNewConsult}
        >
          Nova consulta
        </Button>
      </div>
    );
  }
}

Result.propTypes = {
  classes: PropTypes.object.isRequired,
  barcode: PropTypes.string.isRequired,
  offer: PropTypes.number,
  price: PropTypes.number.isRequired,
  onNewConsult: PropTypes.func.isRequired,
};

const enhance = compose(
  withStyles(styles),
)
export default enhance(Result);
