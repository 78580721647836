import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { compose } from "recompose";
import PropTypes from 'prop-types';

const styles = () => ({
  container: {
    textAlign: 'justify',
    padding: '1rem'
  }
});

class LocationBlocked extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <h3>Localização bloqueada :(</h3>
        <p>Precisamos saber sua localização para podermos buscar os menores preços mais próximos de você.</p>
        <p>Vamos também utilizar sua localização para compartilhar os valores informados por você com as outras pessoas que estão utilizando o APP.</p>
        <p>Mas pode ficar tranquilo! Não compartilhamos sua localização com ninguém, apenas os valores!</p>
      </div>
    );
  }
}

LocationBlocked.propTypes = {
  classes: PropTypes.object.isRequired,
};


const enhance = compose(
  withStyles(styles),
)

export default enhance(LocationBlocked);
