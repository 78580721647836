
import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import BarcodeReader from 'BarcodeReader/BarcodeReader';
import { compose } from 'recompose';
import Header from 'Header/Header';
import { withStyles } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home from 'Home/Home';
import Login from 'Login/Login';
import PrivateRoute from 'Login/PrivateRoute';
import { yellowBg } from 'storage/constants';
import { Favorite } from '@material-ui/icons';

const styles = () => ({
  body: {
    backgroundColor: yellowBg,
    display:'grid',
    gridTemplateColumns: '100vw',
    gridTemplateRows: 'auto 1fr auto',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'auto',
  },
  footer: {
    backgroundColor: 'red',
    textAlign: 'center',
    color: 'white',
  }
});

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      coords: null,
      locationBlocked: false
    }
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    const { classes } = this.props;
    return (
      <main className={classes.body}>
        <CssBaseline />
        <Router>
          <Header />
          <Switch>
            <PrivateRoute path="/comparar-mercados">
              <BarcodeReader />
            </PrivateRoute>
            {/* <Route path="/comparar-produto">
              <h1>TODO</h1>
            </Route> */}
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/">
              <Home/>
            </Route>
          </Switch>
          <footer className={classes.footer}>
            <strong>Ta Barato</strong>, Feito com <Favorite/> em Blumenau-SC, Brasil. <br/>
            © 2020 - 2020
          </footer>
        </Router>
      </main>
    );
  }
}

App.propTypes = {
  coords: PropTypes.object,
};


const enhance = compose(
  withStyles(styles)
)

export default enhance(App);
