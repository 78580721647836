
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from "recompose";
import { withStyles } from '@material-ui/styles';
import BackspaceIcon from '@material-ui/icons/Backspace';
import { Search } from '@material-ui/icons';
import BarcodeErrorIcon from 'storage/icons/BarcodeErrorIcon';

const styles = () => ({
  keyboard: {
    display: 'grid',
    width: '100vw',
    height: '30vh',
    gridTemplateColumns: '25vw 25vw 25vw 25vw',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: 'gray',
    backgroundColor: '#f6f6f6',
    '&>div': {
      border: '1px solid gray',
      paddingTop: '0.8rem',
    }
  },
  searchButton: {
    color: 'red',
    '&>svg': {
      animation: '$pulse 1s infinite',
    },
  },
  '@keyframes pulse': {
    from: { transform: 'scale(1)'},
    to: { transform: 'scale(1.5)'},
  },

});


class Keyboard extends React.Component {
  state = {
    quantity: 1,
    price: '0',
  }

  constructor() {
    super();
    this.handleNumberInput = this.handleNumberInput.bind(this);
    this.handleBackSpace = this.handleBackSpace.bind(this);
    this.handleUnitChange = this.handleUnitChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      quantity: this.props.quantity,
      price: String(this.props.price).replace('.', ''),
    })
  }

  toDecimal(value) {
    return parseFloat((parseFloat(value) / 100).toFixed(2));
  }

  handleNumberInput(number) {
    return (event) => {
      this.setState((state) => {
        let newPrice = this.state.price || '';
        newPrice = newPrice + number;
        this.props.onChange(
          this.toDecimal(newPrice),
          state.quantity
        );
        return {
          price: newPrice,
        }
      });
    };
  }

  handleBackSpace(event) {
    this.setState((state) => {
      let newPrice = state.price;
      newPrice = newPrice.slice(0, newPrice.length - 1) || '0';
      this.props.onChange(
        this.toDecimal(newPrice),
        state.quantity
      )
      return {
        price: newPrice,
      }
    });
  }

  handleUnitChange(value) {
    return () => {
      this.setState(state => {
        const newQuantity = state.quantity + value;
        if (newQuantity < 1) { return null; }
        this.props.onChange(
          this.toDecimal(state.price),
          newQuantity
        );
        return {
          quantity: newQuantity,
        }
      });
    }
  }

  render() {
    const { classes, onSearch, onCancelPriceRead } = this.props;

    return (
      <div className={classes.keyboard}>
        <div onClick={this.handleNumberInput(1)}>1</div>
        <div onClick={this.handleNumberInput(2)}>2</div>
        <div onClick={this.handleNumberInput(3)}>3</div>
        <div onClick={this.handleBackSpace}><BackspaceIcon /></div>
        <div onClick={this.handleNumberInput(4)}>4</div>
        <div onClick={this.handleNumberInput(5)}>5</div>
        <div onClick={this.handleNumberInput(6)}>6</div>
        <div onClick={this.handleUnitChange(1)}>+</div>
        <div onClick={this.handleNumberInput(7)}>7</div>
        <div onClick={this.handleNumberInput(8)}>8</div>
        <div onClick={this.handleNumberInput(9)}>9</div>
        <div onClick={this.handleUnitChange(-1)}>-</div>
        <div>#</div>
        <div onClick={this.handleNumberInput(0)}>0</div>
        <div><BarcodeErrorIcon onClick={onCancelPriceRead}/></div>
        <div onClick={onSearch} className={classes.searchButton}><Search /></div>
      </div>
    );
  }
}

Keyboard.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  onSearch: PropTypes.func.isRequired,
  onCancelPriceRead: PropTypes.func.isRequired,
};

const enhance = compose(
  withStyles(styles),
)
export default enhance(Keyboard);
